import React from "react";
import { SEO, PageWrapper } from "../components/Common";

const FourOfFour = () => (
  <PageWrapper>
    <SEO title="404 Page Not Found" />
    <h1>404 Page Not Found</h1>
  </PageWrapper>
);

export default FourOfFour;
